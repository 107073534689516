@import '../../includes/all';

$page-heading-bg: rgba(255, 255, 255, .8);
$page-heading-color: $text-color-light;
$benefits-bg: rgba($theme-bg, .8);
$benefits-color: white;

h1 {
  margin: 0;
  background-color: $page-heading-bg;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

#booking-tool {
  @include desktop {
    min-height: 480px;
  }

  @include mobile {
    height: auto;
  }

  background-image: url($home-page-bg);
  height: 70vh;
}

#heading-container {
  position: relative;
  height: 100%;

  h1 {
    color: $page-heading-color;
    width: 50%;
    min-width: 375px;
    max-width: 100%;
    padding: 10px 15px;
    margin: 0;
    background: none;
    text-shadow: 0 0 8px black;
    font-size: 24px;
    
    strong {
      color: $theme-text;
    }

    @include desktop {
      position: absolute;
      top: 50%;
      left: 130px;
      transform: translateY(-50%);
      font-size: 40px;
    }

    @include phone {
      min-width: 0;
      width: auto;
    }
  }
}

#benefits {
  @include mobile {
    position: static;
  }

  @include phone {
    width: auto;
  }

  background: $benefits-bg;
  color: $benefits-color;
  list-style: none;
  margin: 15px 0 0 auto;
  padding: 10px 15px;
  width: 300px;
  max-width: 100%;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, .3); // sass-lint:disable-line no-color-literals
  position: absolute;
  right: 0;
  bottom: 0;

   > li {
    padding: 1px 0;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &::before {
      float: left;
      content: '';
      width: 30px;
      height: 20px;
      background: url($benefits-checkmark-img) no-repeat;
      background-size: contain;
      margin-right: 5px;
    }
  }
}
