@import 'media';

@mixin clearfix {
  &::before {
    content: ' ';
    display: table;
  }

  &::after {
    content: ' ';
    display: table;
    clear: both;
  }
}

@mixin boxshadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24); // sass-lint:disable-line no-color-literals
}

@mixin font-regular {
  font-family: 'Open Sans', 'Lato', sans-serif;
  font-weight: 400;
  font-smoothing: antialiased;
}

@mixin font-semibold {
  font-weight: 600;
}

@mixin font-bold {
  font-weight: 800;
}

@mixin font-light {
  font-weight: 300;
  font-family: 'Open Sans Light', 'Open Sans', 'Lato', sans-serif;

  strong,
  b {
    @include font-regular;
    color: inherit;
  }
}

@mixin phone {
  @media only screen and (max-width: $small) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: $medium) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $medium) {
    @content;
  }
}
