@import '../includes/all';

$city-bg: rgba($theme-bg, .8);
$city-text: white;
$price-bg: rgba(255, 255, 255, .9);

#popular-cities {
  margin-top: 0;
}

#cities {
  border-radius: 3px;
  overflow: hidden;

  &,
  span {
    color: $theme-text;
  }
}

.city,
.all-destinations {
  @include mobile {
    width: 50%;
  }

  @include phone {
    height: 150px;
  }

  width: 25%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 170px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .city-title {
    background-color: $city-bg;
    text-align: center;
    padding: 5px 0;
    color: $city-text;
    font-size: 18px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .city-price-box {
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: $price-bg;
    padding: 5px 7px;
  }

  .city-price {
    font-size: 26px;
  }

  .city-price-from {
    vertical-align: top;
    font-size: 12px;
  }
}

.city {
  &:nth-child(1) {
    background-image: url($destination1-img);
  }

  &:nth-child(2) {
    background-image: url($destination2-img);
  }

  &:nth-child(3) {
    background-image: url($destination3-img);
  }
}

.all-destinations {
  background-image: url($all-destinations-img);
}
