@import '../includes/all';

$plugin-height: 230px;
$header-height: 90px;
$widget-bg: rgba(0, 0, 0, .5);
$bg-while-booking: white;

html.gete-booking-in-progress {
  #booking-tool {
    padding: 0 !important;
    height: calc(100vh - #{$header-height});
    background: $bg-while-booking !important;
  }

  #currency-switcher {
    display: inline-block !important;
  }
}

#booking-tool {
  @include desktop {
    padding-bottom: $plugin-height;
  }

  min-height: 230px;
  overflow: visible;
  position: relative;

  > .site-component {
    height: 100%;
  }
}

#gete-widget {
  @include mobile {
    display: none !important;
  }

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 12px;
  min-width: $medium;
}

#form-container {
  background-color: $widget-bg;

  > #mainForm {
    margin: 0 auto;
    padding: 15px;
    max-width: 990px;
  }
}

#start-message {
  padding: 45px 0 0 60px;

  > img {
    display: inline-block;
    height: 40px;
    position: relative;
    bottom: 30px;
    padding-right: 10px;
  }

  > div {
    display: inline-block;
    vertical-align: bottom;

    > div:first-child {
      font-size: 16px;
    }
  }
}

#gete-widget-frame {
  height: 100%;
  width: 100%;
  min-height: 500px;
  z-index: 100;
  border: 0;
}
