@import '../../includes/all';

$review-container-bg: white;
$section-bg: #f4f4f4;

#review-section {
  background-color: $section-bg;
  padding-bottom: 60px;
}

#reviews {
  @include mobile {
    height: auto;
    text-align: center;
  }

  height: 260px;
  max-width: none;

  > div {
    width: 30%;
    float: left;
    text-align: center;

    &:not(:last-child) {
      margin-right: 5%;
    }

    @include mobile {
      float: none;
      vertical-align: top;
      min-width: 180px;
      width: auto;
      max-width: 40%;

      .review-user-image {
        float: right;
      }
    }

    @include phone {
      width: 100%;
      margin: 0 0 35px !important;
      max-width: none;
    }

    > div {
      &:first-child {
        // Review text
        @include mobile {
          min-height: 0;
        }

        box-shadow: 0 2px 10px 1px rgba(0, 0, 0, .3); // sass-lint:disable-line no-color-literals
        padding: 15px;
        min-height: 170px;
        background-color: $review-container-bg;
      }

      &:last-child {
        // Review user
        margin-top: 20px;

        .review-user-image {
          height: 60px;
          width: 60px;
          border-radius: 50%;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          margin: 0 auto 10px;
        }
      }
    }
  }

  .review-title {
    @include font-semibold;

    font-size: 16px;
    font-style: italic;
  }

  .review-rating {
    margin: 10px auto;
  }

  .review-user {
    font-size: 16px;
  }
}

.rating-star {
  background-image: url($review-star);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 3px;
}

.review-user-image1 {
  background-image: url($reviewer1-img);
}

.review-user-image2 {
  background-image: url($reviewer2-img);
}

.review-user-image3 {
  background-image: url($reviewer3-img);
}
