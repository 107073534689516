@import '../../includes/all';

$btn-bg: white;
$btn-text: black;
$btn-active-text: $theme-text;
$btn-border: #f4f4f4;
$btn-hover-color: #5e5e5e;

#service-levels {
  text-align: center;
  font-size: 16px;

  > button {
    width: 25%;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: $btn-bg;
    color: $btn-text;
    border: 0;
    border-bottom: 5px solid transparent;
    padding-bottom: 10px;
    font-size: 18px;
    outline: none !important;
    transition: all 1s;

    &.active {
      color: $btn-active-text;
      cursor: default;
      border-bottom-color: $btn-active-text;
    }

    &:not(:last-child) {
      border-right: 1px solid $btn-border;
    }

    &:not(.active):hover {
      color: $btn-hover-color;
      border-bottom-color: $btn-border;
    }
  }
}

#service-levels-images {
  @include mobile {
    height: auto;
  }

  height: 300px;

  &:not(.slick-initialized) {
    visibility: hidden;
    max-height: 300px;
    overflow: hidden;
  }

  > div {
    border-radius: 3px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .3); // sass-lint:disable-line no-color-literals
  }
}
