@import '../includes/all';

.cta-btn {
  display: block;
  height: auto !important;
  font-size: 21px !important;
  padding: 5px 15px !important;
  margin: 15px auto auto !important;
  width: auto !important;

  > .fa {
    font-size: 16px;
    margin-left: 5px;
  }
}

@include desktop {
  #cta-btn-container {
    display: none !important;
  }
}
