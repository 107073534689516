@import '../../includes/all';

$phone-img-bg: rgba(0, 0, 0, .01);
$heading-color: black;

html.no-flexbox {
  #leisure-trip .row > div {
    display: inline-block;
    vertical-align: top;
  }
}

#leisure-trip {
  .row {
    @include phone {
      display: block;

      > div {
        width: auto !important;
      }
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      width: 47%;

      img {
        width: 100%;
      }

      p {
        @include font-light;
        font-size: 24px;
      }

      &:first-child {
        // Image
        @include phone {
          border-radius: 10px;
          background-color: $phone-img-bg;
        }

        height: 400px;
        width: 450px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    &:nth-of-type(even) {
      > div:first-child {
        order: 2;
      }
    }
  }

  h3 {
    font-size: 45px;

    > strong {
      color: $heading-color;
    }
  }

  img.divider {
    display: block;
    width: 50%;
    margin: 10px auto;
    clear: both;

    &:nth-of-type(even) {
      // Flip horizontally
      transform: scaleX(-1);
      filter: fliph;
    }
  }

  @include phone {
    h3 {
      font-size: 28px;
      text-align: center;
    }

    .row > div > p {
      font-size: 16px;
      text-align: justify;
    }
  }

  @include mobile {
    .row {
      img {
        width: 90%;
        float: none !important;
        display: block;
        margin: auto;
        max-width: 400px;
      }
    }

    img.divider {
      display: none;
    }
  }
}

.trip-img1 {
  background-image: url($stories1-img);
}

.trip-img2 {
  background-image: url($stories2-img);
}

.trip-img3 {
  background-image: url($stories3-img);
}
